@font-face {
    font-family: 'Knockout';
    src:
        url('../fonts/Knockout-HTF48-Featherweight.woff2') format('woff2'),
        url('../fonts/Knockout-HTF48-Featherweight.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src:
        url('../fonts/Calibri-Italic.woff2') format('woff2'),
        url('../fonts/Calibri-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
