html {
    font-size: 20px;
}
body {
    --text-color: #000;
    --text-reverse: #fff;
    --accent-color: #1c449c;
    --surface-1: #dbdbdb;
    --surface-2: #606060;

    font-size: 16px;
    color: var(--text-color);
    background-color: var(--text-reverse);
}

.calibri {
    font-family: 'Calibri', sans-serif;
    line-height: 1;
}

.logo {
    padding: 1rem;
    display: block;
}

#app {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
    padding: 1rem;
    gap: 1rem;
}

#send-message-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input,
    textarea {
        color: var(--text-color);
        background-color: var(--surface-1);
        appearance: none;
        background-image: none;
        border: 0 none transparent;
        padding: 0.5rem;
    }

    textarea {
        aspect-ratio: 1.25;
        width: 100%;
    }

    #btn-submit {
        font-family: 'Knockout', sans-serif;
        display: block;
        background-color: var(--accent-color);
        color: var(--text-reverse);
        font-size: 2.5rem;
        line-height: 1;
        cursor: pointer;
        transition: transform 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);

        &:active {
            transform: scale(0.95);
        }
    }
}

.success {
    background-color: var(--accent-color);
    color: var(--text-reverse);
    font-family: 'Knockout', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    line-height: 1.2;
    aspect-ratio: 1.5;
    text-align: center;
    padding: 2rem;
}

.privacy {
    margin-top: 2rem;
    font-size: 16px;
    color: var(--surface-2);
}

a.link {
    color: var(--accent-color);
    font-weight: bold;
    text-decoration: underline;
}

.disclaimer {
    font-size: 16px;
    text-align: center;
}

#preloader {
    display: none;
    position: fixed;
    z-index: 999;
    inset: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.8);
}

#preloader .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px;
    border: 4px solid var(--accent-color);
    border-top: 4px solid var(--text-reverse);
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
